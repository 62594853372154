import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Autocomplete,
  Avatar,
  Card,
  CircularProgress,
  Grid,
  Icon,
  InputAdornment,
  Modal,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";

import MDBox from "components/CTFramework/MDBox";
import MDInput from "components/CTFramework/MDInput";
import MDTypography from "components/CTFramework/MDTypography";
import MDButton from "components/CTFramework/MDButton";
import { AxiosContext } from "context";
import ProgramDetails from "components/ProgramDetails";
import PaymentMethod from "components/PaymentMethod";
import Formatters from "helpers/formatters";
import Utils from "helpers/utils";
import NewClient from "components/NewClient";

function NewSell({ isOpen, onClose }) {
  const { authAxios } = useContext(AxiosContext);

  const [amount, setAmount] = useState(0);
  const [clients, setClients] = useState([]);
  const [cost, setCost] = useState(0);
  const [cpm, setCPM] = useState(0);
  const [date, setDate] = useState(new Date());
  const [description, setDescription] = useState("");
  const [installments, setInstallments] = useState(1);
  const [isOpenNewClient, setIsOpenNewClient] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [paymentDate, setPaymentDate] = useState(new Date());
  const [paymentMethod, setPaymentMethod] = useState("");
  const [saving, setSaving] = useState(false);
  const [selectedClient, setSelectedClient] = useState({ id: "" });
  const [selectedProgram, setSelectedProgram] = useState({ id: "" });

  const save = async () => {
    if (amount > selectedProgram?.statistics?.amountInStock) {
      toast.error("Quantidade de pontos insuficiente para realizar a venda!");
      return;
    }
    if (
      selectedClient.id !== "" &&
      selectedProgram.id !== "" &&
      amount > 0 &&
      cost >= 0 &&
      paymentMethod !== ""
    ) {
      try {
        setSaving(true);
        await authAxios.post("/rewards/v1/transactions", {
          amount,
          client: selectedClient,
          costPerUnit: Utils.getCPMInUnit(cpm, selectedProgram.config.unitMeasure),
          date,
          description,
          type: "outcoming",
          payment: {
            dateStart: paymentDate,
            installments,
            method: paymentMethod,
            value: cost,
          },
          program: selectedProgram,
        });

        onClose(true);
        toast.success("Venda realizada com sucesso!");
      } catch (err) {
        toast.error(err.response.data.message);
      } finally {
        setSaving(false);
      }
    } else {
      toast.error("Preencha os campos obrigatórios!");
    }
  };

  const fetchClients = async () => {
    const responseClients = await authAxios.get(`/crm/v1/clients`);
    setClients(responseClients.data);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await authAxios.get(`/rewards/v1/programs`);
      setPrograms(response.data);
      fetchClients();
    };

    if (isOpen) {
      fetchData();

      setAmount(0);
      setCost(0);
      setCPM(0);
      setDate(new Date());
      setDescription("");
      setInstallments(1);
      setPaymentDate(new Date());
      setPaymentMethod("");
      setSelectedClient({ id: "" });
      setSelectedProgram({ id: "" });
    }
  }, [isOpen]);

  return (
    <Modal open={isOpen} onClose={() => onClose(false)}>
      <MDBox
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: 650,
        }}
      >
        <Card color="primary">
          <MDBox
            variant={selectedProgram.id !== "" ? "contained" : "gradient"}
            bgColor={selectedProgram.id !== "" ? selectedProgram.config.color : "info"}
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            display="flex"
            justifyContent="space-between"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Nova venda de pontos
            </MDTypography>
          </MDBox>

          <MDBox display="flex" flexDirection="row">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox display="flex" flexDirection="column">
                  <MDBox mb={2} mx={2} mt={1} display="flex" flexDirection="row">
                    <Autocomplete
                      disableClearable
                      value={selectedProgram}
                      options={programs}
                      getOptionLabel={(option) =>
                        option?.config?.name ? `${option.config.name} - ${option.description}` : ""
                      }
                      onChange={(event, newValue) => {
                        setSelectedProgram(newValue);
                      }}
                      fullWidth
                      renderInput={(params) => <MDInput {...params} label="Programa" />}
                      noOptionsText="Programa não encontrado"
                    />

                    {selectedProgram?.config?.icon && (
                      <MDBox ml={1}>
                        <Avatar
                          variant="rounded"
                          sizes="small"
                          src={`img/icons/programs/${selectedProgram.config.icon}`}
                        />
                      </MDBox>
                    )}
                  </MDBox>

                  <MDBox mb={2} mx={2} mt={1} display="flex" flexDirection="row">
                    <Autocomplete
                      disableClearable
                      value={selectedClient}
                      options={clients}
                      getOptionLabel={(option) => option?.name || ""}
                      onChange={(event, newValue) => {
                        setSelectedClient(newValue);
                      }}
                      fullWidth
                      renderInput={(params) => <MDInput {...params} label="Cliente" />}
                      noOptionsText="Cliente não encontrado"
                    />
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={() => {
                        setIsOpenNewClient(true);
                      }}
                      sx={{ marginLeft: 1 }}
                    >
                      <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    </MDButton>
                  </MDBox>
                  <MDBox mb={2} mx={2}>
                    <DatePicker
                      label="Data da transação"
                      inputFormat="dd/MM/yyyy"
                      value={date}
                      onChange={(newDate) => {
                        setDate(newDate);
                        setPaymentDate(newDate);
                      }}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  </MDBox>

                  <MDBox mb={2} mx={2}>
                    <MDInput
                      type="number"
                      label="Quantidade"
                      fullWidth
                      value={amount}
                      error={amount < 0}
                      onChange={(e) => setAmount(Number(e.target.value))}
                      InputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      }}
                    />
                  </MDBox>

                  <MDBox mb={2} mx={2} display="flex" justifyContent="flex-end">
                    <MDInput
                      type="number"
                      label="Valor total"
                      fullWidth
                      value={cost}
                      error={cost < 0}
                      onChange={(e) => {
                        setCost(Number(e.target.value));
                        setCPM(
                          Utils.getCostInUnit(
                            e.target.value / amount,
                            selectedProgram.config.unitMeasure
                          ).toFixed(2)
                        );
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      }}
                    />
                    <MDBox color="text" mx={1} mt={1.5} lineHeight={0}>
                      <MDTypography variant="button" fontWeight="medium">
                        ou
                      </MDTypography>
                    </MDBox>
                    <MDInput
                      type="number"
                      label="CPM"
                      fullWidth
                      value={cpm}
                      error={cpm < 0}
                      onChange={(e) => {
                        setCPM(Number(e.target.value));
                        if (selectedProgram?.config?.unitMeasure) {
                          setCost(
                            Number(
                              e.target.value *
                                Utils.getCPMInUnit(amount, selectedProgram.config.unitMeasure)
                            ).toFixed(2)
                          );
                        }
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      }}
                    />
                  </MDBox>

                  <MDBox mb={2} mx={2}>
                    <MDInput
                      type="text"
                      label="Descrição"
                      fullWidth
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </MDBox>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox display="flex" flexDirection="column" mt={1}>
                  <PaymentMethod
                    cost={cost}
                    installments={installments}
                    setInstallments={setInstallments}
                    paymentDate={paymentDate}
                    setPaymentDate={setPaymentDate}
                    paymentMethod={paymentMethod}
                    setPaymentMethod={setPaymentMethod}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>

          <MDBox display="flex" flexDirection="row">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox display="flex" flexDirection="column">
                  <MDBox mb={2} ml={2} mr={2} shadow="md" coloredShadow="light" borderRadius="lg">
                    <MDTypography
                      component="span"
                      variant="button"
                      fontWeight="bold"
                      color="text"
                      style={{ marginLeft: 15 }}
                    >
                      Saldo do programa {selectedProgram?.config?.name}
                    </MDTypography>
                    {selectedProgram?.config?.icon && (
                      <ProgramDetails
                        amountInStock={selectedProgram.statistics.amountInStock}
                        costPerUnit={selectedProgram.statistics.costPerUnit}
                        unitMeasure={selectedProgram.config.unitMeasure}
                      />
                    )}
                  </MDBox>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox display="flex" flexDirection="column">
                  <MDBox mb={2} ml={2} mr={2} shadow="md" coloredShadow="light" borderRadius="lg">
                    <MDTypography
                      component="span"
                      variant="button"
                      fontWeight="bold"
                      color="text"
                      style={{ marginLeft: 15 }}
                    >
                      Resultado
                    </MDTypography>
                    {selectedProgram?.config?.icon && (
                      <MDBox pb={1} pl={2} display="flex" justifyContent="space-between">
                        <MDBox display="flex" flexDirection="column" justifyContent="right">
                          <MDTypography component="p" variant="button" color="text" display="flex">
                            Preço medio&nbsp;
                          </MDTypography>
                          <MDTypography
                            component="span"
                            variant="button"
                            fontWeight="bold"
                            color="success"
                          >
                            {Formatters.currency(cpm)}
                          </MDTypography>
                        </MDBox>
                        <MDBox px={2} display="flex" flexDirection="column" justifyContent="right">
                          <MDTypography component="p" variant="button" color="text" display="flex">
                            Lucro&nbsp;
                          </MDTypography>
                          <MDTypography
                            component="span"
                            variant="button"
                            fontWeight="bold"
                            color={
                              cost - amount * selectedProgram.statistics.costPerUnit > 0
                                ? "success"
                                : "error"
                            }
                          >
                            {Formatters.currency(
                              cost - amount * selectedProgram.statistics.costPerUnit
                            )}
                            {cost &&
                              `  / ${(
                                ((cost - amount * selectedProgram.statistics.costPerUnit) * 100) /
                                cost
                              ).toFixed(2)}%`}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    )}
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>

          <MDBox mt={4} mb={1} mr={1} ml={1} display="flex" justifyContent="space-between">
            <MDButton
              variant="gradient"
              color="secondary"
              onClick={() => {
                onClose(false);
              }}
            >
              Voltar
            </MDButton>
            <MDButton variant="gradient" color="primary" onClick={save}>
              {saving ? (
                <MDBox display="flex" justifyContent="space-between" color="text">
                  <CircularProgress color="text" size={15} />
                  &nbsp;Salvando
                </MDBox>
              ) : (
                "Salvar"
              )}
            </MDButton>
          </MDBox>
        </Card>

        {isOpenNewClient && (
          <NewClient
            isOpen={isOpenNewClient}
            onClose={(reload, id) => {
              if (reload) {
                fetchClients();
                setSelectedClient(id);
              }
              setIsOpenNewClient(false);
            }}
          />
        )}
      </MDBox>
    </Modal>
  );
}

NewSell.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NewSell;
