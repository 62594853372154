import PropTypes from "prop-types";
import Card from "@mui/material/Card";

import MDBox from "components/CTFramework/MDBox";
import DataTable from "components/DataTable";

function List({ rows }) {
  const columns = [
    { Header: "Descrição", accessor: "description", width: "400px", align: "left" },
    { Header: "Data", accessor: "date", align: "right" },
    { Header: "Pontos", accessor: "points", align: "right" },
    { Header: "Valor", accessor: "value", align: "right" },
    { Header: "PM", accessor: "avaragePrice", align: "right" },
    { Header: "", accessor: "action", align: "right", width: "5px" },
  ];

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} pb={2} px={2}>
        <MDBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{ listStyle: "none" }}
        >
          <DataTable
            table={{ columns, rows }}
            noEndBorder
            entriesPerPage={false}
            isSorted={false}
          />
        </MDBox>
      </MDBox>
    </Card>
  );
}

List.propTypes = {
  rows: PropTypes.array.isRequired,
};

export default List;
