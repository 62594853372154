import { useContext, useEffect, useState } from "react";
import { AxiosContext, useAuth } from "context";
import { Icon, Grid, Card, Backdrop, CircularProgress } from "@mui/material";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import MDBox from "components/CTFramework/MDBox";
import ReportsBarChart from "components/Charts/BarCharts/ReportsBarChart";
import MDTypography from "components/CTFramework/MDTypography";
import Formatters from "helpers/formatters";
import NewTransaction from "components/NewTransaction";
import MDButton from "components/CTFramework/MDButton";
import NewTransfer from "components/NewTransfer";
import NewSell from "components/NewSell";
import Utils from "helpers/utils";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import DataTable from "components/DataTable";
import NewEmission from "components/NewEmission";
import Footer from "components/Footer";

function Dashboard() {
  const { authAxios } = useContext(AxiosContext);
  const { getUser } = useAuth();

  const [billsToCashFlow, setBillsToCashFlow] = useState({});
  const [isOpenNewEmission, setIsOpenNewEmission] = useState(false);
  const [isOpenNewTransaction, setIsOpenNewTransaction] = useState(false);
  const [isOpenNewTransfer, setIsOpenNewTransfer] = useState(false);
  const [isOpenNewSell, setIsOpenNewSell] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dashboardStatistics, setDashboardStatistics] = useState({});
  const [rows, setRows] = useState([]);

  const columns = [
    { Header: "Programa", accessor: "program", align: "left" },
    { Header: "CPF's Usados", accessor: "emissions", align: "right" },
    { Header: "Quantidade", accessor: "ammount", align: "right" },
    { Header: "Custo", accessor: "cost", align: "right" },
    { Header: "CPM", accessor: "avarage", align: "right" },
    { Header: "Resultado Último preço", accessor: "sellInLastPrice", align: "center" },
    {
      Header: "Resultado média últimas seis",
      accessor: "sellInAvarageLastSix",
      align: "center",
    },
    { Header: "Resultado média", accessor: "sellInAvarage", align: "center" },
  ];

  const parseRows = (items) => {
    setRows(
      items.map((item) => ({
        program: item.program,
        ammount: Formatters.number(item.statistics.amountInStock),
        emissions: item.statistics.emissions,
        cost: Formatters.currency(item.statistics.costPerUnit * item.statistics.amountInStock),
        avarage: (
          <MDTypography variant="button" fontWeight="regular">
            {Formatters.currency(
              Utils.getCostInUnit(item.statistics.costPerUnit, item.config.unitMeasure)
            )}
          </MDTypography>
        ),
        sellInLastPrice: (
          <MDTypography
            variant="button"
            fontWeight="medium"
            color={item.resultInLastPrice > 0 ? "success" : "error"}
          >
            {item.sellInLastPrice > 0 &&
              Formatters.currency(
                Utils.getCostInUnit(item.costInLastPrice, item.config.unitMeasure)
              )}
            {item.sellInLastPrice > 0 && " / "}
            {item.sellInLastPrice > 0 && Formatters.number(item.percentInLastPrice)}
            {item.sellInLastPrice > 0 && "% / "}
            {item.sellInLastPrice > 0 && Formatters.currency(item.resultInLastPrice)}
          </MDTypography>
        ),
        sellInAvarageLastSix: (
          <MDTypography
            variant="button"
            fontWeight="medium"
            color={item.resultInAvarageLastSix > 0 ? "success" : "error"}
          >
            {item.sellInAvarageLastSix > 0 &&
              Formatters.currency(
                Utils.getCostInUnit(item.costInAvarageLastSix, item.config.unitMeasure)
              )}
            {item.sellInAvarageLastSix > 0 && " / "}
            {item.sellInAvarageLastSix > 0 && Formatters.number(item.percentInAvarageLastSix)}
            {item.sellInAvarageLastSix > 0 && "% / "}
            {item.sellInAvarageLastSix > 0 && Formatters.currency(item.resultInAvarageLastSix)}
          </MDTypography>
        ),
        sellInAvarage: (
          <MDTypography
            variant="button"
            fontWeight="medium"
            color={item.resultInAvarage > 0 ? "success" : "error"}
          >
            {item.sellInAvarage > 0 &&
              Formatters.currency(Utils.getCostInUnit(item.costInAvarage, item.config.unitMeasure))}
            {item.sellInAvarage > 0 && " / "}
            {item.sellInAvarage > 0 && Formatters.number(item.percentInAvarage)}
            {item.sellInAvarage > 0 && "% / "}
            {item.sellInAvarage > 0 && Formatters.currency(item.resultInAvarage)}
          </MDTypography>
        ),
      }))
    );
  };

  const fetchData = async () => {
    setLoading(true);
    const statistics = authAxios.get("/user/v1/dashboard").then((response) => {
      setDashboardStatistics(response.data);
      parseRows(response.data.programsToSell);
    });

    const flow = authAxios
      .get(`/financial/v1/bills/cash/flow?dateStart=${format(new Date(), "yyyy-MM-dd")}`)
      .then((response) => {
        const incoming = {
          labels: [],
          datasets: { label: "", data: [] },
        };

        const outcoming = {
          labels: [],
          datasets: { label: "", data: [] },
        };

        response.data.incoming.forEach((item) => {
          incoming.labels.push(item.month);
          incoming.datasets.data.push(item.value);
        });

        response.data.outcoming.forEach((item) => {
          outcoming.labels.push(item.month);
          outcoming.datasets.data.push(item.value);
        });

        setBillsToCashFlow({
          incoming,
          outcoming,
        });
      });

    Promise.all([statistics, flow]).then(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!loading && (
        <MDBox py={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} mb={4}>
              <Card id="actions">
                <MDBox display="flex" justifyContent="space-between" py={2} px={2}>
                  <MDBox py={2} px={2} width="70%">
                    <MDBox display="flex" justifyContent="space-between">
                      <MDTypography>Olá, {getUser().name}</MDTypography>
                      <MDBox
                        py={2}
                        px={2}
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        sx={{ borderLeft: "1px solid #000" }}
                      >
                        <MDBox display="flex" flexDirection="column">
                          <MDTypography
                            component="span"
                            variant="button"
                            fontWeight="regular"
                            style={{ width: 120 }}
                          >
                            Saldo previsto do mês
                          </MDTypography>
                          <MDTypography
                            component="span"
                            variant="button"
                            fontWeight="bold"
                            color={dashboardStatistics?.balance > 0 ? "success" : "error"}
                          >
                            {Formatters.currency(dashboardStatistics?.balance)}
                          </MDTypography>
                        </MDBox>
                        <MDBox display="flex" flexDirection="column" ml={2}>
                          <MDTypography
                            component="span"
                            variant="button"
                            fontWeight="regular"
                            style={{ width: 120 }}
                          >
                            Assinaturas previstas do mês
                          </MDTypography>
                          <MDTypography
                            component="span"
                            variant="button"
                            fontWeight="bold"
                            color="error"
                          >
                            {Formatters.currency(dashboardStatistics?.subscriptionsValue)}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                    <MDBox display="flex" justifyContent="space-between">
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={3}>
                          <Link to="/financial/incoming">
                            <Card>
                              <MDBox
                                py={2}
                                px={2}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                              >
                                <MDTypography
                                  component="span"
                                  variant="button"
                                  fontWeight="regular"
                                >
                                  Receita do mês
                                </MDTypography>
                                <MDTypography
                                  component="span"
                                  variant="button"
                                  fontWeight="bold"
                                  color="success"
                                >
                                  {Formatters.currency(dashboardStatistics?.incomeMonth)}
                                </MDTypography>
                              </MDBox>
                            </Card>
                          </Link>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Link to="/financial/outcoming">
                            <Card>
                              <MDBox
                                py={2}
                                px={2}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                              >
                                <MDTypography
                                  component="span"
                                  variant="button"
                                  fontWeight="regular"
                                >
                                  Despesa do mês
                                </MDTypography>
                                <MDTypography
                                  component="span"
                                  variant="button"
                                  fontWeight="bold"
                                  color="error"
                                >
                                  {Formatters.currency(dashboardStatistics?.expensesMonth)}
                                </MDTypography>
                              </MDBox>
                            </Card>
                          </Link>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Link to="/programs">
                            <Card>
                              <MDBox
                                py={2}
                                px={2}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                              >
                                <MDTypography
                                  component="span"
                                  variant="button"
                                  fontWeight="regular"
                                >
                                  Saldo em Caixa
                                </MDTypography>
                                <MDTypography
                                  component="span"
                                  variant="button"
                                  fontWeight="bold"
                                  color="info"
                                >
                                  {Formatters.currency(dashboardStatistics?.accountBalance)}
                                </MDTypography>
                              </MDBox>
                            </Card>
                          </Link>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Link to="/financial/reports">
                            <MDButton variant="text" color="warning" style={{ paddingTop: "2em" }}>
                              <Icon sx={{ fontWeight: "bold" }}>insights</Icon> &nbsp; Ver
                              Relátorios
                            </MDButton>
                          </Link>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </MDBox>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    sx={{ borderLeft: "1px solid #000" }}
                    pt={2}
                    px={2}
                  >
                    <MDTypography>Acessos rápido</MDTypography>
                    <MDBox pt={3}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={6}>
                          <MDButton
                            variant="gradient"
                            color="success"
                            onClick={() => {
                              setIsOpenNewSell(true);
                            }}
                            style={{ display: "flex", flexDirection: "rown", width: 100 }}
                          >
                            <Icon sx={{ fontWeight: "bold", paddingRight: "1.5em" }}>
                              attach_money
                            </Icon>
                            &nbsp;Venda
                          </MDButton>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <MDButton
                            variant="gradient"
                            color="info"
                            onClick={() => {
                              setIsOpenNewTransaction(true);
                            }}
                            style={{ display: "flex", flexDirection: "rown", width: 100 }}
                          >
                            <Icon sx={{ fontWeight: "bold", paddingRight: "1.5em" }}>
                              currency_exchange
                            </Icon>
                            &nbsp;Acumulo
                          </MDButton>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <MDButton
                            variant="gradient"
                            color="secondary"
                            onClick={() => {
                              setIsOpenNewTransfer(true);
                            }}
                            style={{ display: "flex", flexDirection: "rown", width: 100 }}
                          >
                            <Icon sx={{ fontWeight: "bold", paddingRight: "1.5em" }}>
                              connecting_airports
                            </Icon>
                            &nbsp;Transf.
                          </MDButton>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <MDButton
                            variant="gradient"
                            color="primary"
                            onClick={() => {
                              setIsOpenNewEmission(true);
                            }}
                            style={{ display: "flex", flexDirection: "rown", width: 100 }}
                          >
                            <Icon sx={{ fontWeight: "bold", paddingRight: "1.5em" }}>
                              airplane_ticket
                            </Icon>
                            &nbsp;Emissão
                          </MDButton>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>

          <MDBox mt={4.5}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={3}>
                  <ReportsBarChart
                    color="success"
                    title="Contas a receber"
                    chart={billsToCashFlow?.incoming}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={3}>
                  <ReportsBarChart
                    color="error"
                    title="Contas a pagar"
                    chart={billsToCashFlow?.outcoming}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>

          <MDBox mt={4.5}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <MDBox mb={3}>
                  <DataTable
                    table={{ columns, rows }}
                    noEndBorder
                    entriesPerPage={{ defaultValue: 20 }}
                    isSorted={false}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      )}

      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {isOpenNewTransaction && (
        <NewTransaction
          isOpen={isOpenNewTransaction}
          onClose={(reload) => {
            if (reload) {
              fetchData();
            }
            setIsOpenNewTransaction(false);
          }}
        />
      )}
      {isOpenNewTransfer && (
        <NewTransfer
          isOpen={isOpenNewTransfer}
          onClose={(reload) => {
            if (reload) {
              fetchData();
            }
            setIsOpenNewTransfer(false);
          }}
        />
      )}
      {isOpenNewSell && (
        <NewSell
          isOpen={isOpenNewSell}
          onClose={(reload) => {
            if (reload) {
              fetchData();
            }
            setIsOpenNewSell(false);
          }}
        />
      )}
      {isOpenNewEmission && (
        <NewEmission
          isOpen={isOpenNewEmission}
          onClose={() => {
            setIsOpenNewEmission(false);
          }}
        />
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
