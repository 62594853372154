import Icon from "@mui/material/Icon";

import Billing from "pages/transactions";
import Dashboard from "pages/dashboard";
import SignIn from "pages/authentication/sign-in";
import SignUp from "pages/authentication/sign-up";
import Programs from "pages/programs";
import Subscriptions from "pages/subscriptions";
import Cards from "pages/cards";
import FinancialIncoming from "pages/financial/incoming";
import FinancialOutcoming from "pages/financial/outcoming";
import PriceQuotes from "pages/priceQuotes";
import FinancialReports from "pages/financial/reports";
import Profile from "pages/profile";
import Emissions from "pages/emissions";
import Clients from "pages/clients";
import Client from "pages/client";
import Issues from "pages/issues";
import Issue from "pages/issue";
import ResetPassword from "pages/authentication/reset-password";
import ConfirmResetPassword from "pages/authentication/confirm-reset-password";
import Notifications from "pages/notifications";

const Routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    sidenav: true,
    private: true,
  },
  {
    type: "collapse",
    name: "Cartões",
    key: "cards",
    icon: <Icon fontSize="small">credit_card</Icon>,
    route: "/cards",
    component: <Cards />,
    sidenav: true,
    private: true,
  },
  {
    type: "collapse",
    name: "Assinaturas",
    key: "subscriptions",
    icon: <Icon fontSize="small">loyalty</Icon>,
    route: "/subscriptions",
    component: <Subscriptions />,
    sidenav: true,
    private: true,
  },
  {
    type: "collapse",
    name: "Programas",
    key: "programs",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/programs",
    component: <Programs />,
    sidenav: true,
    private: true,
  },
  {
    type: "collapse",
    name: "Transações",
    key: "transactions",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/transactions",
    component: <Billing />,
    sidenav: true,
    private: true,
  },
  {
    type: "collapse",
    name: "Emissões",
    key: "emissoes",
    icon: <Icon fontSize="small">airplane_ticket</Icon>,
    route: "/emissoes",
    component: <Emissions />,
    sidenav: true,
    private: true,
  },
  {
    type: "title",
    title: "Cotação Milhas",
    key: "quotes",
    sidenav: true,
  },
  {
    type: "collapse",
    name: "Gráfico",
    key: "quotes/chart",
    icon: <Icon fontSize="small">timeline</Icon>,
    route: "/quotes/chart",
    component: <PriceQuotes />,
    sidenav: true,
    private: true,
  },
  {
    type: "title",
    title: "Financeiro",
    key: "financial",
    sidenav: true,
  },
  {
    type: "collapse",
    name: "Receitas",
    key: "financial/incoming",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/financial/incoming",
    component: <FinancialIncoming />,
    sidenav: true,
    private: true,
  },
  {
    type: "collapse",
    name: "Despesas",
    key: "financial/outcoming",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/financial/outcoming",
    component: <FinancialOutcoming />,
    sidenav: true,
    private: true,
  },
  {
    type: "collapse",
    name: "Relatório",
    key: "financial/reports",
    icon: <Icon fontSize="small">insights</Icon>,
    route: "/financial/reports",
    component: <FinancialReports />,
    sidenav: true,
    private: true,
  },
  {
    type: "title",
    title: "CRM",
    key: "crm",
    sidenav: true,
  },
  {
    type: "collapse",
    name: "Clientes",
    key: "clients",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/clients",
    component: <Clients />,
    sidenav: true,
    private: true,
  },
  {
    type: "title",
    title: "Ferramentas",
    key: "tools",
    sidenav: true,
  },
  // {
  //   type: "collapse",
  //   name: "Calculadoras",
  //   key: "calculators",
  //   icon: <Icon fontSize="small">calculate</Icon>,
  //   route: "/calculators",
  //   component: <Calculators />,
  //   sidenav: true,
  //   private: true,
  // },
  {
    type: "collapse",
    name: "Cliente",
    key: "client",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/client",
    component: <Client />,
    sidenav: false,
    private: true,
  },
  {
    type: "collapse",
    name: "Suporte",
    key: "supportissue",
    icon: <Icon fontSize="small">help</Icon>,
    route: "/support",
    component: <Issues />,
    sidenav: true,
    private: true,
  },
  {
    type: "collapse",
    name: "Chamado",
    key: "issue",
    icon: <Icon fontSize="small">help</Icon>,
    route: "/issue",
    component: <Issue />,
    sidenav: false,
    private: true,
  },
  {
    type: "collapse",
    name: "Perfil",
    key: "conta",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/conta",
    component: <Profile />,
    sidenav: false,
    private: true,
  },
  {
    type: "collapse",
    name: "Notificações",
    key: "notifications",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/notifications",
    component: <Notifications />,
    sidenav: false,
    private: true,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/login",
    component: <SignIn />,
    sidenav: false,
    private: false,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/cadastro",
    component: <SignUp />,
    sidenav: false,
    private: false,
  },
  {
    type: "collapse",
    name: "Reset Password",
    key: "reset-password",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/reset-password",
    component: <ResetPassword />,
    sidenav: false,
    private: false,
  },
  {
    type: "collapse",
    name: "Confirm Reset Password",
    key: "confirm-reset-password",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/confirm-reset",
    component: <ConfirmResetPassword />,
    sidenav: false,
    private: false,
  },
];

export default Routes;
